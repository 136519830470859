.container-gallery {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    min-height: 100vh;
    background: #222;
    margin-top: 78px;

    .btn-close-gallery {
        width: 100px;
        font-size: 40px;
        background: none;
        color: white;
        border: none;

        svg {
            path {
                color: white;
            }
        }
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        /* Compensate for excess margin on outer gallery flex items */
        margin: -1rem -1rem;
        padding: 40px;

        .gallery-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 24rem;
            margin: 1rem;
            overflow: hidden;

            .gallery-image {
                align-self: center;
                justify-self: center;
                display: block;
                transition: transform 400ms ease-out;

                img {
                    max-width: 450px;
                    max-height: 400px;
                }

                &:hover {
                    transform: scale(1.03);
                }
            }

        }
    }

    @supports (display: grid) {
        .gallery {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .gallery,
        .gallery-item {
            margin-left: 0;
        }
    }

    @include breakpoint(medium) {
        .gallery {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .gallery {
            .gallery-item {
                display: flex;

                .gallery-image {
                    align-self: center;
                    justify-self: center;

                    img {
                        max-width: 350px;
                    }
                }
            }
        }
    }

    @include breakpoint(small) {
        .gallery {
            display: grid;
            grid-template-columns: 1fr;
        }

        .gallery {
            .gallery-item {
                .gallery-image {
                    img {
                        max-width: 250px;
                    }
                }
            }
        }
    }
}