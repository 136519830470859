.login-box {
    width: 400px;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;

    h2 {
        margin: 0 0 30px;
        padding: 0;
        color: #fff;
        text-align: center;
    }

    .user-box {
        position: relative;

        .text-area {
            width: 100%;
            padding: 10px 0;
            font-size: 16px;
            color: #fff;
            margin-top: 15px;
            border: none;
            border-bottom: 1px solid #fff;
            outline: none;
            background: transparent;
        }

        input {
            width: 100%;
            padding: 10px 0;
            font-size: 16px;
            color: #fff;
            margin-top: 15px;
            border: none;
            border-bottom: 1px solid #fff;
            outline: none;
            background: transparent;

            &:focus~label,
            &:valid~label {
                top: -20px;
                left: 0;
                color: #03e9f4;
                font-size: 12px;
            }
        }

        label {
            position: absolute;
            top: 0;
            left: 0;
            padding: 10px 0;
            font-size: 16px;
            color: #fff;
            pointer-events: none;
            transition: .5s;
        }
    }
    .btn-submit {
        margin-top: 30px;
        @include btn-primary(100px);
    
        .btn-3 {
            & span:hover {
                color: #eee;
            }
        }
    }
    
}
@include breakpoint(small){
    .login-box{
        width: 300px;
    }
}
