@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

@font-face {
    font-family: LeafyPlantSansFree;
    src: url(../../../fonts/LeafyPlantSansFree.ttf);
}

@font-face {
    font-family: Higer;
    src: url(../../../fonts/Higher.ttf);
}