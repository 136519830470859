.footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
    background-color: #333;
    color: #eee;

    h6 {
        text-align: center;
    }

    img {
        max-width: 150px;
        height: auto;
    }

    p {
        justify-self: center;
        text-align: center;
    }

    .redes {
        display: inline-flex;
        list-style: none;
        justify-content: center;
        align-items: center;
        color: #eee;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: 60px;

        .icon {
            position: relative;
            background: #eee;
            border-radius: 50%;
            color: #eee;
            padding: 5px;
            margin: 10px;
            width: 30px;
            height: 30px;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            &:hover .nombre-red {
                top: -45px;
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }

            &:hover span,
            &:hover .nombre-red {
                text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
            }
        }

        .nombre-red {
            position: absolute;
            top: 0;
            font-size: 14px;
            background: #ffffff;
            color: #ffffff;
            padding: 5px 8px;
            border-radius: 5px;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

            &::before {
                position: absolute;
                content: "";
                height: 8px;
                width: 8px;
                background: #ffffff;
                bottom: -3px;
                left: 50%;
                transform: translate(-50%) rotate(45deg);
                transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            }
        }

        a {
            color: #222;
        }

        .facebook:hover,
        .instagram:hover,
        .youtube:hover,
        .envelope:hover {
            a {
                color: white;
            }
        }

        .facebook:hover,
        .facebook:hover .nombre-red,
        .facebook:hover .nombre-red::before {
            background: #1877F2;
        }

        .instagram:hover,
        .instagram:hover .nombre-red,
        .instagram:hover .nombre-red::before {
            background: #E4405F;
        }

        .youtube:hover,
        .youtube:hover .nombre-red,
        .youtube:hover .nombre-red::before {
            background: #CD201F;
        }

        .envelope:hover,
        .envelope:hover .nombre-red,
        .envelope:hover .nombre-red::before {
            background: #25D366;
        }

    }

    .contac-list{
        list-style: none;
        padding-left: 0;
        li{
            color: #999;
            padding: 5px;
        }
    }
    .donaciones{
        max-width: 400px;
    }
}

.createBy{
    background-color: #222;
    color: rgb(208, 208, 208);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
}
@include breakpoint(small) {
    .footer {
        flex-direction: column;

        .redes {
            padding: 10px;

            .icon {
                margin: 5px;
                padding: 0;
            }
        }
    }
}