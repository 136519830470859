.biografia-content{    
    .content-tittle {
        @include titles-sections($color_prime);
    }
    
    .foto-biografia {
        display: block;
        margin: auto;
        width: 200px;
        height: auto;
        margin-bottom: 40px;
    }
    
    .biografia {
        width: 50%;
        margin: 60px auto;
        font-family: 'Libre Baskerville', serif;
        color: #222;
        font-size: 1.2rem;
    }
    @include breakpoint(small){
        .biografia {
            width: 90%;
            font-size: 1rem;
        }
    }
}