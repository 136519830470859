@mixin btn-primary($ancho) {
    .custom-btn {
        color: #fff;
        border-radius: 5px;
        background: transparent;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
            7px 7px 20px 0px rgba(0, 0, 0, .1),
            4px 4px 5px 0px rgba(0, 0, 0, .1);
        outline: none;
    }

    .btn-3 {
        background-color: #982453;
        width: $ancho;
        height: 40px;
        line-height: 42px;
        padding: 0;
        border: none;

        span {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
        }

        &:before,
        &:after {
            position: absolute;
            content: "";
            right: 0;
            top: 0;
            background: #982453;
            transition: all 0.3s ease;
        }

        &:before {
            height: 0%;
            width: 2px;
        }

        &:after {
            width: 0%;
            height: 2px;
        }

        &:hover {
            background: transparent;
            box-shadow: none;
        }

        &:hover:before {
            height: 100%;
        }

        &:hover:after {
            width: 100%;
        }

        & span:hover {
            color: #982453;
        }

        & span:before,
        & span:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            background: #982453;
            transition: all 0.3s ease;
        }

        & span:before {
            width: 2px;
            height: 0%;
        }

        & span:after {
            width: 0%;
            height: 2px;
        }

        & span:hover:before {
            height: 100%;
        }

        & span:hover:after {
            width: 100%;
        }
    }
}

@mixin breakpoint($point) {
    @if $point ==big {
        @media screen and (min-width: 601px) {
            @content;
        }
    }

    @else if $point ==medium {
        @media screen and (max-width: 1024px) {
            @content;
        }
    }

    @else if $point ==small {
        @media screen and (max-width: 600px) {
            @content;
        }
    }
}

@mixin titles-sections($color) {
    padding: 30px auto;

    .tittle-section {
        text-align: center;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $color;
        padding: 20px 0;

        .span-title {
            border-bottom: solid 1px #777;
            padding-bottom: 5px;
        }

        .color-letter {
            color: $color_prime;
        }

        @include breakpoint(big) {
            font-weight: 700;
            font-size: 2.5rem;

        }

        @include breakpoint(small) {
            font-size: 1.8rem;
        }
    }

    ;
}

@mixin libro {
    $images: url("../../../images/novedades/concurso1.jpeg"),
    url("../../../images/novedades/concurso2.jpeg"),
    url("../../../images/novedades/concurso3.jpeg"),
    url("../../../images/novedades/concurso2.jpeg"),
    url("../../../images/novedades/concurso3.jpeg"),
    url("../../../images/novedades/concurso1.jpeg");

    // Preload to images
    .imgLoader {
        position: fixed;
        animation: preLoad 1s steps(1);
        width: 1px;
        height: 1px;

        @keyframes preLoad {
            @for $i from 0 through 4 {
                #{$i * 10}% {
                    background-image: nth($images, ($i + 1));
                }
            }

            100% {
                display: none;
            }
        }
    }

    .libro {
        position: relative;
        width: 380px;
        height: 380px;
    }

    .book {
        position: relative;
        perspective: 630px;
        perspective-origin: center 50px;
        transform: scale(0.8);
        filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
    }

    .page {
        width: 210px;
        height: 300px;
        background-color: #bbb;
        position: absolute;
        top: 0px;
        right: 50%;
        transform-origin: 100% 100%;
        border: solid $borderColor 2px;
        background-size: 420px 300px;
        background-position: center;
        transform-style: preserve-3d;

        &:nth-child(1) {
            transform: rotateX($bookAngle) rotateY(3deg);
        }

        &:nth-child(2) {
            transform: rotateX($bookAngle) rotateY(4.5deg);
        }

        &:nth-child(3) {
            transform: rotateX($bookAngle) rotateY(6deg);
            animation: nextPage $speed*5 infinite $speed*-4.8 steps(1);
            background-size: 420px 300px;
            background-position: -2px -2px;
        }

        &:nth-child(4) {
            transform: rotateX($bookAngle) rotateY(177deg);
        }

        &:nth-child(5) {
            transform: rotateX($bookAngle) rotateY(175.5deg);
        }

        &:nth-child(6) {
            transform: rotateX($bookAngle) rotateY(174deg);
            overflow: hidden;

            &::after {
                content: '';
                width: 210px;
                height: 300px;
                position: absolute;
                top: 0px;
                right: 0%;
                transform-origin: center;
                transform: rotateY(180deg);
                animation: nextPage $speed*5 $speed*-4 infinite steps(1);
                background-size: 420px 300px;
                background-position: 100% -2px;
            }
        }

        @keyframes nextPage {
            @for $i from 0 through 4 {
                #{$i * 20}% {
                    background-image: nth($images, ($i + 1));
                }
            }
        }
    }

    .gap {
        width: 10px;
        height: 300px;
        background: none;
        transform: rotateX($bookAngle);
        transform-origin: bottom;
        position: absolute;
        top: 0px;
        left: calc(50% - 5px);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            background-color: $borderColor;
            width: 10px;
            height: 5px;
            border-radius: 50%;
        }
    }

    .pages,
    .flips {
        transform-style: preserve-3d;
    }

    .flip {
        width: 32px;
        height: 300px;
        position: absolute;
        top: 0px;
        transform-origin: 100% 100%;
        right: 100%;
        border: solid $borderColor;
        border-width: 2px 0px;
        perspective: 4200px;
        perspective-origin: center;
        transform-style: preserve-3d;
        background-size: 420px 300px;

        &::after {
            content: '';
            position: absolute;
            top: 0px;
            right: 0%;
            width: 100%;
            height: 100%;
            transform-origin: center;
            background-size: 420px 300px;
        }

        &.flip1 {
            right: 50%;
            animation: flip1 $speed infinite ease-in-out;
            border-width: 2px 2px 2px 0;

            &::after {
                animation: nextFlip1 $speed*5 $speed*-4 infinite steps(1);
            }
        }

        &:not(.flip1) {
            right: calc(100% - 2px);
            top: -2px;
            transform-origin: right;
            animation: flip2 $speed ease-in-out infinite;
        }

        @for $i from 2 through 7 {
            &.flip#{$i}::after {
                animation: nextFlip#{$i} $speed*5 $speed*-4 infinite steps(1);
            }
        }

        &.flip7 {
            width: 30px;
            border-width: 2px 0px 2px 2px;

            &::after {
                animation: nextFlip7 $speed*5 $speed*-4 infinite steps(1);
            }
        }

        @keyframes flip1 {

            0%,
            20% {
                transform: rotateX($bookAngle) rotateY(6deg);
            }

            80%,
            100% {
                transform: rotateX($bookAngle) rotateY(174deg);
            }
        }

        @keyframes flip2 {

            0%,
            20% {
                transform: rotateY(0deg) translateY(0px);
            }

            50% {
                transform: rotateY(-15deg) translateY(0px);
            }
        }
    }

    @keyframes nextFlip1 {
        @for $i from 0 through 4 {
            #{$i * 20}% {
                background-image: nth($images, ($i + 1));
                background-position: -178px -2px;
                transform: rotateY(0deg);
            }

            #{10 + ($i * 20)}% {
                background-image: nth($images, ($i + 2));
                background-position: -210px -2px;
                transform: rotateY(180deg);
            }
        }
    }

    @for $i from 2 through 6 {
        @keyframes nextFlip#{$i} {
            @for $j from 0 through 4 {
                #{$j * 20}% {
                    background-image: nth($images, ($j + 1));
                    background-position: #{-148 + (($i - 2) * 30)}px -2px;
                    transform: rotateY(0deg);
                }

                #{((10 + ($j * 20)) + (($i - 1) * 0.5))}% {
                    background-image: nth($images, ($j + 2));
                    background-position: #{-238 - (($i - 2) * 30)}px -2px;
                    transform: rotateY(180deg);
                }
            }
        }
    }

    @keyframes nextFlip7 {
        @for $i from 0 through 4 {
            #{$i * 20}% {
                background-image: nth($images, ($i + 1));
                background-position: -2px -2px;
                transform: rotateY(0deg);
            }

            #{13 + ($i * 20)}% {
                background-image: nth($images, ($i + 2));
                background-position: -388px -2px;
                transform: rotateY(180deg);
            }
        }
    }

    .twitterLink {
        position: fixed;
        bottom: 0.5em;
        right: 0.5em;

        & img {
            width: 2em;
            filter: grayscale(100%);
            transition: filter 0.25s;

            &:hover {
                filter: grayscale(0%);
            }
        }
    }
}