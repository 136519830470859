.content-video {
    width: 100%;
    background-color: #673047;
    height: 100vh;

    .content-title {
        margin: auto;
        padding: 30px 0;

        .tittle-section {
            text-align: center;
            color: #e1e1e1;
            padding: 20px;

            .span-title {
                border-bottom: solid 1px #e1e1e1;
                padding-bottom: 10px;
            }
        }
    }

    .carousel {
        .carousel-indicators {
            bottom: -50px !important;
        }

        .video {
            display: block;
            width: 60%;
            height: 60vh;
            margin: auto;
        }

        .carousel-control-prev {
            .icon-carrusel-videos {
                font-size: 60px;
                color: #fff;
            }
        }

        .carousel-control-next {
            .icon-carrusel-videos {
                font-size: 60px;
            }
        }
    }
}