@media screen and (max-width:1200px) {

    // Bibliografia

    //obras
    .content-obras {
        .contet-libro {
            min-width: 90%;
            margin-left: 5%;

            .img-libro {
                img {
                    width: 150px;
                    padding: 10px;
                }
            }

            .info-libro {
                ::-webkit-scrollbar {
                    width: 6px;
                }
                
                ::-webkit-scrollbar-thumb {
                    background-color: #999;
                    border-radius: 10px;
                }
                
                .resenia-libro {
                    max-height: 200px;
                    overflow-y: scroll;
                }
            }
        }

        ul {
            padding-bottom: 20px !important;
        }

        .fondo-curvo {
            min-height: 100px;
            padding-top: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .biografia {
        width: 80%;
    }
}

@media screen and (max-width:500px) {

    // Bibliografia
    .biografia {
        font-size: 1rem;
    }

    //obras
    .content-obras {
        .contet-libro {
            min-width: 90%;
            margin-left: 5%;

            .img-libro {
                img {
                    width: 100px;
                    padding: 10px;
                }
            }

            .info-libro {
                .resenia-libro {
                    font-size: 0.9rem;
                }
            }
        }

        .pagination {
            .page-item {
                .page-link {
                    padding: 5px;
                }
            }
        }
    }

}