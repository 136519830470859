.contar-content {
    overflow: hidden;

    .fondo-curvo-activado {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #eee;
        margin-bottom: 100px;

        .curve {
            position: absolute;
            height: 120px;
            width: 100%;
            bottom: 0;
            text-align: center;
            background-color: $color-primary;
        }

        .curve::before {
            content: '';
            display: block;
            position: absolute;
            border-top: solid 5px #683047;
            border-left: solid 5px #683047;
            border-radius: 100% 36%;
            width: 55%;
            height: 100%;
            transform: translate(85%, 60%);
            background-color: #eee;
        }

        .curve::after {
            content: '';
            display: block;
            position: absolute;
            border-radius: 100% 70%;
            border-bottom: solid 5px #eee;
            border-right: solid 5px #eee;
            width: 55%;
            height: 100%;
            background-color: #683047;
            transform: translate(-4%, 40%);
            z-index: 1;
        }
    }

    .content-title {
        @include titles-sections($colo-title-grey);
    }

    .title-contar {
        text-align: center;
    }

    .title-edition {
        text-align: center;
    }

    .img-ed{
        display: block;
        margin: auto;
        width: 50vh;
    }
    .link-gallery {
        display: block;
        margin: auto;
        text-align: center;
        border: none;
        background: none;
        height: 45px;
        font-size: 1.2rem;
        color: $color-prime;
    }

    @include breakpoint(small){
        .img-ed{
            width: 250px;
        }
    }
}