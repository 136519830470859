.content-body {
    .division-banner {
        background-color: #eee;
        padding: 0 0 20px 0;

        h2 {
            text-align: center;
            margin: 0 !important;

            img {
                max-width: 100px;
            }
        }

        h2.background {
            position: relative;
            z-index: 1;

            &:before {
                content: "";
                margin: 0 auto;
                /* this centers the line to the full width specified */
                position: absolute;
                /* positioning must be absolute here, and relative positioning must be applied to the parent */
                top: 50%;
                left: 0;
                right: 0;
                bottom: 0;
                width: 95%;
                z-index: -1;
            }

            span {
                /* to hide the lines from behind the text, you have to set the background color the same as the container */
                background: #eee;
                padding: 0 15px;
            }
        }

        h2.double:before {
            /* this is just to undo the :before styling from above */
            border-top: none;
        }

        h2.double:after {
            border-bottom: 1px solid #982453;
            -webkit-box-shadow: 0 1px 0 0 #982453;
            -moz-box-shadow: 0 1px 0 0 #982453;
            box-shadow: 0 1px 0 0 #982453;
            content: "";
            margin: 0 auto;
            /* this centers the line to the full width specified */
            position: absolute;
            top: 45%;
            left: 0;
            right: 0;
            width: 95%;
            z-index: -1;
        }
    }

    .quienes-somos {
        display: flex;
        background-color: #eee;
        gap: 20px;
        padding: 60px 50px 0 50px;

        .content-title {
            @include titles-sections($colo-title-grey)
        }

        iframe {
            margin: 50px 0;
            width: 40%;
            height: 50vh;
            border: solid 1px $color_prime;
            padding: 5px;
        }

        .manifiesto-index {
            padding: 0 0 60px 0;
            width: 50%;
            font-family: 'Libre Baskerville', serif;
            color: #222;
            font-size: 1.2rem;
            margin-right: 50px;

            .color-letter {
                color: #982453
            }

            @include btn-primary(150px);

            .custom-btn {
                display: block;
                margin: 40px auto;
                text-decoration: none;

            }
        }
    }

    .actividades {
        background: url("../../images/img-body/bg_bggenerator_com.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.3s;
        padding: 50px 10px;

        .content-title {
            @include titles-sections($colo-title-grey)
        }
    }

    // .novedades {
    //     background-color: #e1e1e1;
    //     transition: 0.3s;
    //     padding: 60px 0;


    //     .gallery {
    //         display: flex;
    //         flex-wrap: wrap;
    //         width: 70%;
    //         margin: auto;

    //         .gallery-item {
    //             /* Minimum width of 24rem and grow to fit available space */
    //             flex: 1 0 300px;
    //             /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
    //             margin: 1rem;
    //             box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
    //             overflow: hidden;

    //             .gallery-image {
    //                 display: block;
    //                 width: 100%;
    //                 height: 100%;
    //                 object-fit: cover;
    //                 transition: transform 400ms ease-out;

    //                 &:hover {
    //                     transform: scale(1.08);
    //                 }
    //             }
    //         }
    //     }
    // }

    .img-book {
        @include libro;
    }
}