.content-novedades {
    min-height: 50vh;
    margin: 100px;
    .img-concurso{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            max-width: 700px;
            border: solid rgb(54, 52, 52) 1px;
            border-radius: 5px;
            margin-bottom: 5px;
        }
        a{
            margin: 5px;
            font-size: xx-large;
        }
    }
    h1{
        text-align: center
    }
    p{
        text-align: center
    }
    .book{
        margin-top: 50px;
        margin-bottom: 400px
    }
    @include libro;

    @include breakpoint(small) {
        .img-concurso{
            img{
                width: 350px;
            }
            a{
                font-size: smaller;
            }
        }
    }
}