@media screen and (max-width:1024px) {
    .content-body {
        .quienes-somos {
            flex-direction: column-reverse;

            iframe {
                display: block;
                width: 80%;
                height: 400px;
                margin: 0 auto;
            }

            .manifiesto-index {
                width: 80%;
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .content-body {
        .quienes-somos {
            padding: 40px 10px;

            iframe {
                width: 90%;
            }

            .manifiesto-index {
                width: 90%;
                font-size: 1rem;
                ;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .content-body {
        .quienes-somos {
            padding: 30px 5px;

            iframe {
                height: 300px;
            }

            .manifiesto-index {
                padding-bottom: 10px;
            }
        }

        .actividades {
            .card {
                width: 300px;

                img {
                    width: 80%;
                    height: 200px;
                    margin: 5px 10%;
                    padding: 5px;
                    border-left: solid 2px #999;
                    border-radius: 5px;

                }

                .card-body {
                    height: 150px;

                    .card-text {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    span {
                        display: none;
                    }

                    button {
                        margin: 0;
                    }
                }
            }

            .novedades {
                .gallery {
                    margin-left: 10%;
                }
            }

            .img-book {
                .libro {
                    .book {
                        transform: scale(0.8);
                    }
                }
            }
        }
    }
}