::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 10px;
}

* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #eee !important;
}