.quienes-somos {
    position: relative;

    .content-title {
        @include titles-sections($colo-title-grey)
    }

    .content-manifiesto {
        width: 50%;
        margin: 0 auto;

        img {
            display: block;
            max-width: 250px;
            height: auto;
            border: solid 1px #999;
            padding: 5px;
            margin: 0 auto;
        }

        .manifiesto-romina {
            h3 {
                display: inline-block;
                border-bottom: solid 1px #555;
                color: #555;
            }

            font-family: 'Libre Baskerville',
            serif;
            color: #444;
            font-size: 1.2rem;

            .span-autor {
                display: block;
                font-weight: 700;
                font-size: 1.1rem;
                color: #111;
                text-align: end;
                margin: 20px
            }

            a {
                text-decoration: none;
                color: $color_prime;
            }
        }

        .manifiesto-silvia {
            @extend .manifiesto-romina;
        }
    }

    .fotos {
        margin: 0 auto;


    }
}

.comunidad {
    font-family: 'Libre Baskerville', serif;
    font-size: 100%;
    line-height: 1.5;
    font-size: inherit;
    line-height: inherit;

    @mixin breakpoint($point) {
        @if $point ==big {
            @media screen and (min-width: 601px) {
                @content;
            }
        }

        @else if $point ==small {
            @media screen and (max-width: 600px) {
                @content;
            }
        }
    }

    $connectorHeight: 42px;
    $connectorBorderWidth: 1px;
    $connectorBorderColor: #d8d8d8;

    $circleSizeSmall: 36px;
    $circlePaddingSmall: 9px;

    $circleSizeLarge: 174px;
    $circlePaddingLarge: 48px;

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    .container {
        box-sizing: content-box;
        padding: 0 12px;
        max-width: 80%;
        margin: 48px auto;
    }

    .step-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .step-list__item {

        position: relative;

        &:before {

            font-weight: 300;
            color: $connectorBorderColor;
        }

        h2 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 300;
        }

        .icon {
            border-radius: 50%;
            display: block;
            flex: 0 0 auto;

            .img {
                width: 100%;
                height: 100%;
            }

            .icon-libro {
                display: block;
                max-width: 120%;
                max-height: 120%;
                margin: auto;
            }
        }

        .body {
            font-size: 14px;
            line-height: 18px;
            font-weight: 300;

            h2 {
                padding: 10px 5px;
                color: #333;
                font-size: 2rem;
                font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

                .span-comunidad {
                    border-bottom: solid 1px #333;
                }
            }

            p {
                font-size: 1.2rem;

                .comunidad-nombres {
                    font-weight: 600;
                    color: #222;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: row;
            padding-bottom: 18px;
        }

        &:first-child {
            .icon {
                background: #f4f6d5;
            }
        }

        &:nth-child(2) {
            .icon {
                background: #e8f4f3;
            }
        }

        &:nth-child(3) {
            .icon {
                background: #ededed;
            }
        }

        &:nth-child(4) {
            .icon {
                background: #edf6ef;
            }
        }

        &:nth-child(5) {
            .icon {
                background: #fef3f3;
            }
        }


        &:nth-child(odd) {
            .content {
                justify-content: flex-start;
            }
        }

        &:nth-child(even) {
            .content {
                justify-content: flex-end;
            }
        }

        &:first-child {
            >.step-list__item__inner {
                &:before {
                    content: none;
                }
            }
        }

        &:last-child {
            >.step-list__item__inner {
                &:after {
                    content: none;
                }
            }
        }

        +li {
            margin-top: $connectorHeight * 2;

            >div {
                margin-top: -$connectorBorderWidth;
            }
        }

        @include breakpoint(small) {
            display: flex;

            &:before {

                position: relative;
                font-size: 36px;
                line-height: 42px;
                font-weight: 300;
                color: #000000;
                margin-right: 12px;
            }

            .icon {
                order: 0;
                width: $circleSizeSmall;
                height: $circleSizeSmall;
                padding: $circlePaddingSmall;
                position: absolute;
                left: -25px;
                top: 42px;
            }

            .body {
                order: 1;
                margin-top: 8px;
                margin-left: 12px;
            }

            .content {
                align-items: flex-start;

                &:before {
                    content: '';
                    position: absolute;
                    border-left: $connectorBorderColor;
                    height: 100%;
                    left: 9px;
                    bottom: 0;
                    display: block;
                    width: 1px;
                    background: $connectorBorderColor;
                    top: 84px;
                }
            }

            &:last-child {
                .content {
                    &:before {
                        content: none;
                    }
                }
            }
        }

        @include breakpoint(big) {
            &:before {

                position: absolute;
                font-size: 90px;
                line-height: 1;
                font-weight: 300;
                color: #d8d8d8;
            }

            .icon {
                height: $circleSizeLarge;
                padding: $circlePaddingLarge;
            }

            .body {
                flex: 0 1 33.3333%;
                margin-top: $connectorHeight * 2;
            }

            .content {
                align-items: flex-end;
            }

            >.step-list__item__inner {
                position: relative;

                &:before,
                &:after {
                    border-width: 0px;
                    border-style: solid;
                    border-color: $connectorBorderColor;
                    display: block;
                    content: '';
                    position: absolute;
                    height: $connectorHeight;
                    width: calc(33.3333% - #{$connectorHeight});

                }
            }

            &:nth-child(odd) {
                >.step-list__item__inner {
                    text-align: right;

                    &:before,
                    &:after {
                        border-left-width: $connectorBorderWidth;
                        left: calc(33.3333% - #{$connectorHeight / 2});
                    }

                    &:before {
                        border-top-width: $connectorBorderWidth;
                        border-top-left-radius: $connectorHeight;
                        margin-top: -$connectorHeight;
                    }

                    &:after {
                        border-bottom-width: $connectorBorderWidth;
                        border-bottom-left-radius: $connectorHeight;
                        margin-bottom: -$connectorHeight;
                    }
                }

                &:before {
                    left: 0;
                    margin-left: 33.3333%;
                    transform: translateX(-100%);
                }

                .icon {
                    margin-left: 72px;
                }
            }

            &:nth-child(even) {
                >.step-list__item__inner {

                    &:before,
                    &:after {
                        border-right-width: $connectorBorderWidth;
                        right: calc(33.3333% - #{$connectorHeight / 2});
                    }

                    &:before {
                        border-top-width: $connectorBorderWidth;
                        border-top-right-radius: $connectorHeight;
                        margin-top: -$connectorHeight;
                    }

                    &:after {
                        border-bottom-width: $connectorBorderWidth;
                        border-bottom-right-radius: $connectorHeight;
                        margin-bottom: -$connectorHeight;
                    }
                }

                &:before {
                    right: 0;
                    margin-right: 33.3333%;
                    transform: translateX(100%);
                }

                .icon {
                    margin-right: 72px;
                }

                .body {
                    order: 1;
                }
            }
        }
    }

}