@media screen and (max-width: 768px) {
    .quienes-somos {
        .content-manifiesto {
            width: 80% !important;

            .manifiesto-romina {
                font-size: 1.1rem;
            }

            .manifiesto-silvia {
                font-size: 1.1rem;
            }
        }

        .fotos {
            img {
                max-width: 150px;
            }
        }
    }
}
