@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Libre+Baskerville:wght@400;700&display=swap');

.contenedor-banner {
  user-select: none;
  display: flex;
  flex-grow: 1;
  background-image: url('../../images/img-header/fondo4.jpg');
  align-items: center;
  max-height: 400px;

  .logo-banner {
    max-height: 250px;
    margin-left: 80px;
  }

  .content-header {
    .title-header {
      display: flex;
      gap: 10px;

      h1 {
        top: 0;
        color: #f5d0b9;
        // font-family: 'Libre Baskerville', serif;
        font-family: 'Kaushan Script', cursive;
        font-size: 4rem;
        font-weight: 500;
        margin-left: 30px;
      }

      .libros {
        height: 100px;
        align-self: flex-end;
        justify-self: flex-end;
      }
    }
  }

  .libros {
    max-width: 100px;
  }

  .content-img-banner {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    overflow: hidden;

    .banner {
      max-height: 400px;
    }
  }
}