.title-card{
    text-align: center;
    color: #E5E7E9 ;
    text-shadow: 2px 2px #222;
    padding-top: 30px;
}

.blog-card {
    display: flex;
    flex-direction: column;
    margin: 25px auto;
    box-shadow: 0 3px 7px -1px rgba(#000, .1);
    margin-bottom: 1.6%;
    background: $color_white;
    line-height: 1.4;
    font-family: 'Libre Baskerville', serif;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;

    &:hover {
        .photo {
            transform: scale(1.03);
        }
    }

    .meta {
        position: relative;
        z-index: 0;
        height: 300px;
    }

    .photo {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        transition: transform .2s;
    }

    .description {
        padding: 1rem;
        background: $color_white;
        position: relative;
        z-index: 1;

        h1 {
            line-height: 1;
            margin: 0;
            font-size: 1.7rem;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        }

        .read-more {
            position: absolute;
            bottom: 10px;
            right: 10px;
            @include btn-primary(100px);
        }

        .video {
            iframe {
                width: 100%;
                height: 300px;
            }
        }

        .link-gallery {
            display: block;
            margin: auto;
            text-align: center;
            border: none;
            background: none;
            height: 45px;
            font-size: 1.2rem;
            color: $color_prime;
        }
    }

    p {
        position: relative;
        margin: 1rem 0 0;

        &:first-of-type {
            margin-top: 1.25rem;
            margin-bottom: 2.5rem;

            &:before {
                content: "";
                position: absolute;
                height: 5px;
                background: $color_prime;
                width: 35px;
                top: -0.75rem;
                border-radius: 3px;
            }
        }
    }


    &:hover {

        .details {
            left: 0%;
        }
    }

    @media (min-width: 640px) {
        flex-direction: row;
        max-width: 700px;
        min-height: 250px;
        padding: 0;

        .meta {
            flex-basis: 40%;
            height: auto;
        }

        .description {
            flex-basis: 60%;

            &:before {
                transform: skewX(-3deg);
                content: "";
                background: #fff;
                width: 30px;
                position: absolute;
                left: -10px;
                top: 0;
                bottom: 0;
                z-index: -1;
            }
        }

        &.alt {
            flex-direction: row-reverse;

            .description {
                &:before {
                    left: inherit;
                    right: -10px;
                    transform: skew(3deg)
                }
            }

            .details {
                padding-left: 25px;
            }
        }
    }
}