.content-julio-act {
    background: url('../../../images/img-body/bg_bggenerator4.jpg');
    background-size: contain;

    .fondo-curvo-activado {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 200px;
        padding-top: 100px;
        background: #eee;
        margin-bottom: 100px;

        .curve {
            position: absolute;
            height: 120px;
            width: 100%;
            bottom: 0;
            text-align: center;
        }

        .curve::before {
            content: '';
            display: block;
            position: absolute;
            border-top: solid 5px #eee;
            border-left: solid 5px #eee;
            border-radius: 100% 36%;
            width: 55%;
            height: 100%;
            transform: translate(85%, 60%);
            background-color: #683047;
        }

        .curve::after {
            content: '';
            display: block;
            position: absolute;
            border-radius: 100% 70%;
            border-bottom: solid 5px #683047;
            border-right: solid 5px #683047;
            width: 55%;
            height: 100%;
            background-color: #eee;
            transform: translate(-4%, 40%);
            z-index: 1;
        }
    }

    .content-title {
        @include titles-sections(rgb(238, 238, 238));
        padding-top: 20px;
        text-shadow: 2px 2px #111
    }

    .celebrando {
        padding: 0 15px 50px 15px;
        color: #eee;

        .celebramos-2020 {
            p {
                font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                max-width: 800px;
                padding: 20px;
                margin: auto;
            }

            a {
                max-width: 700px;

                img {
                    display: block;
                    max-width: 700px;
                    margin: auto;
                }
            }
        }

        .blog-card {
            @media screen {
                max-width: 1000px !important;
            }
        }
    }

    @include breakpoint(small) {
        .content-title {
            .tittle-section{
                font-size: 1.7rem;
            }
        }
        .celebrando {
            .celebramos-2020 {
                a {
                    img {
                        max-width: 300px;
                    }
                }
            }

            .celebramos-2021 {
                .fotos-2021 {
                    img {
                        max-width: 300px;
                    }
                }
            }

            .celebramos-2022 {
                .fotos-2022 {
                    img {
                        max-width: 300px;
                    }
                }
            }
        }
    }
}