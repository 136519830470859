.content-tittle {
    @include titles-sections($colo-title-grey);
}

.content-text-21-julio {
    width: 60%;
    font-family: 'Libre Baskerville', serif;
    margin: auto;
    padding: 20px;
    border-radius: 10px;

    .title-21-julio {
        text-align: center;
    }

    .text-21-julio {
        font-size: 1.1rem;
    }

}

.title-noticias {
    margin: 80px 20% 20px 20%;
    display: inline-block;
    border-bottom: solid 1px #999;
    padding-bottom: 5px;
}

.container-noticias {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 60%;
    margin-left: 20%;
    padding-bottom: 60px;

    .noticias {
        display: flex;

        img {
            width: 300px;
            padding: 5px;
            margin: 20px;
            border: solid 1px #999;
            transition: 0.3s;

            &:hover {
                transform: scale(1.05);
                box-shadow: 10px 5px #999;
            }
        }
    }
}

@include breakpoint(small) {
    .content-text-21-julio {
        width: 90%
    }
}