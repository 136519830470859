@media screen and (max-width: 1200px) {
    .navbar {
        .container-fluid {
            .custom-btn {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 990px) {
    .navbar {
        .container-fluid {
            .custom-btn {
                display: block;
            }
        }
    }
}